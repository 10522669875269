import React, {useEffect, useState} from 'react';
import './../Practice/CircleButton.css';

export default function GetLocation() {
  const [long, setLong] = useState(0);
  const [lat, setLat] = useState(0);
  const [acc, setAcc] = useState(0);
  const [locErrMess, setLocErrMess] = useState('');
  const [locErrCode, setLocErrCode] = useState('');
  const [butStatus, setButStatus] = useState(true);
  const [butBackColor, setButBackColor] = useState("red");
  let buttonColor = "red";
  const styles = {
    button: {
      color: "black",
      backgroundColor: butBackColor,
      borderRadius: "50%",
    }
  }
  let options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  };
  const success = (pos) => {
    let crd = pos.coords;

    console.log("Found Ya: ");
    console.log(`Latitude : ${crd.latitude}`);
    setLat(crd.latitude);
    console.log(`Longitude: ${crd.longitude}`);
    setLong(crd.longitude);
    console.log(`More or less ${crd.accuracy} meters.`);
    setAcc(crd.accuracy);
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
    setLocErrCode(err.code);
    setLocErrMess(err.message);
  };

  const findMe = () => {
    if (navigator.geolocation) {
      setButStatus(false);
      navigator.permissions
        .query({name: "geolocation"})
        .then(function (result) {
          if (result.state === "granted") {
            // make button GREEN
            console.log('result.state', result.state);
            navigator.geolocation.getCurrentPosition(success);
          } else if(result.state === "prompt"){
            console.log('result.state', result.state);
            navigator.geolocation.getCurrentPosition(success, errors, options);
          } else if(result.state === "denied"){
            //If denied then you have to show instructions to enable location
            // make button RED
          }
          result.onchange = function () {
            console.log('result.state', result.state);
          };
        });
    } else {
      alert("Sorry Not Available!");
    }
  }

  useEffect(() => {
    if (navigator.geolocation) {
    setTimeout(() => {
      setButStatus(false);
      setButBackColor("green");
    }, 5000);
    console.log("go");
    }
  });
  return (
    <div>
      <button className='spin circle' style={styles.button} onClick={findMe} disabled={butStatus}>Find Me! <p>long: {long} + lat: {lat} + acc: {acc}</p></button>
    </div>
  )
}
