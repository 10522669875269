/* jshint esversion: 6 */
import React, {useState} from 'react';

export const FinAcctsContext = React.createContext({
  finAccts: [],
  tranx: []
});

const tranxList = ["$50", "$23", "$200", "$12", "$43"];
export default props => {
  const [finAcctsList, setFinAcctsList] = useState([
    {id:'slide-1', name:"AZFCU Pers", bal:"$1000.00", tranx: []},
    {id:'slide-2', name:"AZFCU Biz", bal:"$1500.00", tranx: []},
    {id:'slide-3', name:"Greenwood", bal:"$10000.00", tranx: []},
    {id:'slide-4', name:"OneUnited", bal:"$100000.00", tranx: []},
    {id:'slide-5', name:"TCU", bal:"$9100.00", tranx: []},
  ]);
  return (
    <FinAcctsContext.Provider value={{finAccts: finAcctsList, tranx: tranxList}}>
      {props.children}
    </FinAcctsContext.Provider>
  );
}