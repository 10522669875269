import React from 'react';
import './App.css';
import GetLocation from './components/Location/GetLocation';
import Button from './components/Practice/Button'
import Location from './components/Practice/Location/Location';

function App() {
  // const buttonClick = () => {
  //   console.log('num')
  // }
  // const styles = {
  //   button: {
  //     background: "none",
  //     border: 0,
  //     boxSizing: "border-box",
  //     margin: "1em",
  //     padding: "1em 2em",
  //     borderRadius: "100%"
  //   }
  // }
  return (
    <div className="App">
      <GetLocation/>
    </div>
  );
}

export default App;
